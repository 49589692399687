const maxFileSize = 30
export function inputHandler(event) {
  if (event.target.closest('[data-comrade-form]')) {
    const element = event.target
    const isValid = element.checkValidity()

    if (element.type === 'file') {
      if (element.files && element.files[0]) {
        const fileSize = element.files[0].size / 1000 / 1000
        const validState = fileSize > maxFileSize
        if (validState) {
          element.setCustomValidity(`Max file size ${maxFileSize} Mb`)
        } else {
          element.setCustomValidity('')
        }
        element.classList.toggle('cft-invalid', validState)
      }
    } else {
      element.classList.toggle('cft-invalid', !isValid)
    }
  }
}
