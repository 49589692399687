function conditionParser(inputString) {
  const chunks = inputString.split('.')
  if (!Array.isArray(chunks) || chunks.length < 5) {
    throw new Error(`custom closure condition parse error, ${inputString}`)
  }
  const targetNode = chunks[0]
  const targetNodeAttr = chunks[1]
  const targetNodeAttrValue = chunks[2]
  const targetValue = chunks[4]
  return {
    node: targetNode,
    nodeAttr: targetNodeAttr,
    nodeAttrValue: targetNodeAttrValue,
    value: targetValue.toLowerCase()
  }
}

export class Condition {
  /**
   *
   * @param {HTMLElement} element
   * @param {HTMLFormElement} form
   * @param {String} condition
   */
  constructor(element, form, condition) {
    this.element = element
    this.elementField = this.element.closest('.cft-field')
    this.elementColumn = this.elementField.parentElement
    this.form = form
    this.condition = conditionParser(condition)
    this.trigger = form.querySelector(
      `${this.condition.node}[${this.condition.nodeAttr}="${this.condition.nodeAttrValue}"]`
    )
    if (this.trigger) {
      this.trigger.addEventListener('input', this.triggerHandler.bind(this))
      this.trigger.dispatchEvent(new Event('input'))
    } else {
      throw new Error(`Trigger element not found in condition: ${condition}`)
    }
  }

  triggerHandler(event) {
    const value = event.target.value + ''
    if (value.toLowerCase() === this.condition.value) {
      this.elementColumn.style.display = ''
    } else {
      this.elementColumn.style.display = 'none'
    }
  }
}
