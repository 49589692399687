let cookiesForSend = [
  '_ga',
  '__utmz',
  '_uc_referrer',
  '_uc_ga_conversion_page',
  '_uc_ga_landing_page',
  '_uc_utm_source',
  '_uc_utm_medium',
  '_uc_utm_campaign',
  '_uc_utm_term',
  '_uc_utm_content',
  '_uc_current_page_url',
  'hubspotutk',
  'FirstSession',
  'ReturningSession',
  '_cft_version',
  '_cft_cookiesForSend_test'
];

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

// From https://firstdigital.co.nz/blog/google-analytics/retrieve-traffic-sources-data-without-google-analytics-cookies/
function fixAnalyticsCookies() {
    return new Promise(resolve => {
        bakeCookie(`_cft_version`, `CFT build: ${new Date(process.env.BUILD_DATE).toUTCString()}`, 182, "/", "", "");

        function crumbleCookie(a) {
            for (var d = document.cookie.split(";"), c = {}, b = 0; b < d.length; b++) {
                var e = d[b].substring(0, d[b].indexOf("=")).trim(),
                    i = d[b].substring(d[b].indexOf("=") + 1, d[b].length).trim();
                c[e] = i
            }
            if (a) return c[a] ? c[a] : null;
            return c
        }

        function bakeCookie(a, d, c, b, e, i) {
            var j = new Date;
            j.setTime(j.getTime());
            c && (c *= 864E5);
            j = new Date(j.getTime() + c);
            document.cookie = a + "=" + escape(d) + (c ? ";expires=" + j.toGMTString() : "") + (b ? ";path=" + b : "") + (e ? ";domain=" + e : "") + (i ? ";secure" : "")
        }

        function writeLogic(n) {
            var a = getTrafficSource(n, window.location.host);  //Insert your domain here

            a = a.replace(/\|{2,}/g, "|");
            a = a.replace(/^\|/, "");
            a = unescape(a);

            bakeCookie(n, a, 182, "/", "", "")		//Cookie expiration sets to 182 days
        };

        function getParam(s, q) {
            try{
                var match = s.match('[?&]' + q + '=([^&]+)');
                return match ? match[1] : '';
//        return s.match(RegExp('(^|&)'+q+'=([^&]*)'))[2];
            } catch(e){
                return '';
            }
        }

        function calculateTrafficSource() {
            var source='', medium='', campaign='', term='', content='';
            var search_engines = [['bing', 'q'], ['google', 'q'], ['yahoo', 'q'], ['baidu', 'q'], ['yandex', 'q'], ['ask', 'q']];	//List of search engines
            var ref = document.referrer;
            ref = ref.substr(ref.indexOf('//')+2);
            window.ref_domain = ref;
            window.ref_path = '/';
            window.ref_search = '';

            // Checks for campaign parameters
            var url_search = document.location.search;

            if(url_search.indexOf('utm_source') > -1) {
                source   = getParam(url_search, 'utm_source');
                medium   = getParam(url_search, 'utm_medium');
                campaign = getParam(url_search, 'utm_campaign');
                term     = getParam(url_search, 'utm_term');
                content  = getParam(url_search, 'utm_content');
            }
            else if (getParam(url_search, 'gclid')) {
                source = 'google';
                medium = 'cpc';
                campaign = '(not set)';
            }
            else if(ref) {

                // separate domain, path and query parameters
                if (ref.indexOf('/') > -1) {
                    ref_domain = ref.substr(0,ref.indexOf('/'));
                    ref_path = ref.substr(ref.indexOf('/'));
                    if (ref_path.indexOf('?') > -1) {
                        ref_search = ref_path.substr(ref_path.indexOf('?')+1);
                        ref_path = ref_path.substr(0, ref_path.indexOf('?'));
                    }
                }
                medium = 'referral';
                source = ref_domain;
                // Extract term for organic source
                for (var i=0; i<search_engines.length; i++){
                    if(ref_domain.indexOf(search_engines[i][0]) > -1){
                        medium = 'organic';
                        source = search_engines[i][0];
                        term = getParam(ref_search, search_engines[i][1]) || '(not provided)';
                        break;
                    }
                }
            }

            return {
                'source'  : source,
                'medium'  : medium,
                'campaign': campaign,
                'term'    : term,
                'content' : content
            };
        }

        function getTrafficSource(cookieName, hostname) {
            var trafficSources = calculateTrafficSource();
            var source = trafficSources.source.length === 0 ? 'direct' : trafficSources.source;
            var medium = trafficSources.medium.length === 0 ? 'none' : trafficSources.medium;
            var campaign = trafficSources.campaign.length === 0 ? 'direct' : trafficSources.campaign;
            // exception

            if(source === window.location.host) {
                source = 'direct';
            }

            if(medium === 'referral') {
                campaign = '';
            }
            var rightNow = new Date();
            var value = 'source='   + source +
                '&medium='  + medium +
                '&campaign='+ campaign +
                '&term='    + trafficSources.term +
                '&content=' + trafficSources.content +
                '&date='    + rightNow.toISOString().slice(0,10).replace(/-/g,"");
            return value;
        }

        // Self-invoking function
        (() => {
            var date = new Date();
            var fr_date = date.getUTCFullYear().toString() + ((date.getUTCMonth() < 9) ? '0' + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth()+1).toString()) + ((date.getUTCDate() < 10) ? '0' + date.getUTCDate().toString() : date.getUTCDate().toString());
            var session = crumbleCookie()['FirstSession'];

            if (typeof session == 'undefined')	// First time session
            {
                writeLogic('FirstSession');
            }
            else {
                writeLogic('ReturningSession');
            }
        })();

        (() => {
            ////// Review & Fix PPC Campaign Issues (Wboost issue)
            const allParams = window.location.search.split('?').map(x => x.split('&'));
            let newCookie = [];
            allParams.forEach(items => {
                if (items.filter((a) => a).length) {
                    items.forEach(item => {
                        const name = item.split('=')[0];
                        const value = item.split('=')[1];

                        if (name !== 'utm_source'
                            && name !== 'utm_medium'
                            && name !== 'utm_campaign'
                            && name !== 'utm_term'
                            && name !== 'utm_content')
                        {
                            bakeCookie(`_cft_${name}`, value, 182, "/", "", "");
                            newCookie.push(`_cft_${name}`);
                        }
                    });
                }
            });
            /////
            bakeCookie(`_cft_newCookie_test`, newCookie, 182, "/", "", "");
            resolve(newCookie);
        })();
    });
}

fixAnalyticsCookies();
export async function utmBody() {
    return new Promise(async (resolve) => {
        const cftCookie = await fixAnalyticsCookies();
        const utmBody = {};
        cookiesForSend.forEach((key) => {
            const value = getCookie(key);
            if (value) {
                utmBody[key] = value;
            }
        });

        if (cftCookie.length) {
            cftCookie.forEach((key) => {
                const value = getCookie(key);
                if (value) {
                    utmBody[key] = value;
                }
            });
        }

        resolve(encodeURIComponent(JSON.stringify(utmBody)));
    });

    // return encodeURIComponent(JSON.stringify(utmBody));
}
