import { akismet } from './akismet'
import { request } from './request'
import { utmBody } from './utm-body'
import { validate } from './validate'
import { eventGenerator } from './event-generator'
import { formDone } from './form-done'

export async function formSubmit(event) {
  if ('comradeForm' in event.target.dataset) {
    const element = event.target
    event.preventDefault()

    if (!validate(element)) {
      document.dispatchEvent(
        eventGenerator('invalid', {
          formElement: element,
          formId: element.dataset.comradeForm
        })
      )
      return false
    }
    if (element.classList.contains('cft-loading')) {
      return false
    }

    const responseState = element.querySelector('[data-cft-response-result]')
    if (responseState) responseState.remove()
    const formData = new FormData(element)
    formData.append('akismet', akismet(element))
    formData.append('utm', await utmBody())
    formData.append('page_name', document.title)
    element.classList.add('cft-loading')
    element.classList.remove('cft-error')

    request({
      url: element.action,
      method: element.method,
      data: formData
    })
      .then((response) => {
        element.classList.add('cft-success')
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url
        }
        element.append(formDone(response.data.message, response.data.error))
        if (response.data.error === true) {
          document.dispatchEvent(
            eventGenerator('error', {
              formElement: element,
              formId: element.dataset.comradeForm,
              error: response.data.message
            })
          )
        } else {
          element.reset()
          document.dispatchEvent(
            eventGenerator('success', {
              formElement: element,
              formId: element.dataset.comradeForm,
              data: response.data
            })
          )
        }
      })
      .catch((err) => {
        if (err.data) {
          element.classList.add('cft-error')
          console.error(err)
        } else {
          console.error(err)
        }
        document.dispatchEvent(
          eventGenerator('error', {
            formElement: element,
            formId: element.dataset.comradeForm,
            error: err
          })
        )
      })
      .finally(() => {
        element.classList.remove('cft-loading')
      })
  }
}
