function controlValid(control, regexp) {
  if (!control.required && control.value === '') return true
  if (control.validity.valid === false) {
    return false
  }
  if (control.required && !control.value) {
    console.warn(`${control.id} invalid value empty`)
    return false
  }
  const disableReg = control.dataset.hasOwnProperty('disableMask')
  const validLength = control.dataset.hasOwnProperty('validLength')
  if (validLength) {
    return +control.dataset.validLength === control.value.length
  }
  if (regexp && !disableReg) {
    const regState = regexp.test(control.value)
    if (!regState) {
      console.warn(
        `${control.name} invalid no match regexp ${regexp} with value: ${control.value}`
      )
        // control.setCustomValidity('Invalid Value');
    }

    return regState
  }
  return true
}

const regexpMap = {
  email: new RegExp(/\S+@\S+\.\S+/),
  tel: new RegExp(/\d{3}-\d{3}-\d{4}/)
}

export function validate(form) {
  const inputs = form.elements
  let formValid = true
  let isFocused = false
  Array.from(inputs).forEach((control) => {
    let regexp = regexpMap[control.type]
    const isValid = controlValid(control, regexp)
    control.classList.toggle('cft-invalid', !isValid)
    if (formValid) {
      formValid = isValid
    }
    if (!isFocused && !isValid) {
      control.focus()
      isFocused = true
    }
  })
  return formValid
}
