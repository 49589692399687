function fallbackText(isError) {
    return isError
        ? "Something wrong, please try again"
        : "Message has been successfully sent";
}

export function formDone(message, error) {
    const element = document.createElement("div");
    element.dataset.cftResponseResult = "";
    element.innerHTML = message || fallbackText(error);
    if (error) {
        element.classList.add("cft-error-container");
    } else {
        element.classList.add("cft-success-container");
    }
    return element;
}
