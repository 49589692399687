import './modules/utm-observer'

import { formSubmit } from './modules/form-submit'
import { inputHandler } from './modules/input-handler'
import formFabric from './modules/form-fabric'

document.addEventListener('submit', formSubmit, false)
document.addEventListener('input', inputHandler, false)
if (document.readyState !== 'loading') {
  formFabric()
} else {
  document.addEventListener('DOMContentLoaded', formFabric)
}
