import { request } from './request'
import VMasker from 'vanilla-masker'
import { Condition } from './condition'

window.VMasker = VMasker;
function createElementFromHTML(htmlString) {
  var div = document.createElement('div')
  div.innerHTML = htmlString.trim()

  return div.firstChild
}
function stylesTemplate(raw, external_key) {
  const styles = Object.entries(raw)
  let stylesTemplate = styles.map((prop) => `${prop[0]}: ${prop[1]};`).join('')
  if (styles.length > 0) {
    return `<style>.cft-form[data-comrade-form="${external_key}"] {${stylesTemplate}}<\/style>`
  } else {
    return ''
  }
}

function eventsForm(form) {
    form.querySelectorAll('input[type="tel"]').forEach((phoneInput) => {
        if (!phoneInput.dataset.hasOwnProperty('disableMask')) {
            VMasker(phoneInput).maskPattern('999-999-9999');
            phoneInput.addEventListener('paste', () => {
                setTimeout(() => {
                    VMasker(phoneInput).maskPattern('999-999-9999');
                }, 100);
            });
        }
    })
}

/**
 *
 * @param {HTMLElement} element
 * @param {String} getPath
 */
async function createForm(element, getPath) {
  const response = await request({
    method: 'get',
    url: getPath
  })

  let template = response.data.html || null
  let styles = response.data.styles
    ? stylesTemplate(response.data.styles, response.data.external_key)
    : null

  if (response.status === 200) {
    const formTemplate = createElementFromHTML(template)
    element.replaceWith(formTemplate)
    if (styles) {
      formTemplate.insertAdjacentHTML('beforeBegin', styles)
    }
    eventsForm(formTemplate)
    formTemplate
      .querySelectorAll('[customclosure^="conditions:"]')
      .forEach((field) => {
        new Condition(
          field,
          formTemplate,
          field.getAttribute('customclosure').replace('conditions:', '')
        )
      })
  } else {
    console.error(response)
  }
}

export default function () {
  document.querySelectorAll('[data-cft-get]').forEach((formPlace) => {
    createForm(formPlace, formPlace.dataset.cftGet)
  })
  document.querySelectorAll('[data-comrade-form]').forEach((form) => {
    eventsForm(form)
  })
  console.log(`CFT build: ${new Date(process.env.BUILD_DATE).toUTCString()}`)
}
