export function akismet(element) {
  const akismet = {}
  Array.from(element.elements).forEach((element) => {
    if (element.dataset.akismet && element.name) {
      const type = element.dataset.akismet
      if (!akismet[`akismet:${type}`]) {
        akismet[`akismet:${type}`] = []
      }
      akismet[`akismet:${type}`].push(element.name)
    }
  })
  return encodeURIComponent(JSON.stringify(akismet))
}
